import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'auth-login', pathMatch: 'full' },
  { path: 'auth-login', loadChildren: './auth-login/auth-login.module#AuthLoginPageModule'},
  { path: 'menu', loadChildren: './menu/menu.module#MenuPageModule'},
  { path: 'producto/:id', loadChildren: './producto/producto.module#ProductoPageModule'},
  { path: 'cliente/:id', loadChildren: './cliente/cliente.module#ClientePageModule'},
  { path: 'proveedor/:id', loadChildren: './proveedor/proveedor.module#ProveedorPageModule'},
  { path: 'usuario/:id', loadChildren: './usuario/usuario.module#UsuarioPageModule'},
  { path: 'personal/:id', loadChildren: './personal/personal.module#PersonalPageModule'},
  { path: 'detalle-venta/:id', loadChildren: './detalle-venta/detalle-venta.module#DetalleVentaPageModule'},
  { path: 'detalle-pago/:id', loadChildren: './detalle-pago/detalle-pago.module#DetallePagoPageModule'},
  { path: 'edita-venta/:id', loadChildren: './edita-venta/edita-venta.module#EditaVentaPageModule'},
  { path: 'edita-pedido/:id', loadChildren: './edita-pedido/edita-pedido.module#EditaPedidoPageModule'},
  {
    path: 'user-change-password',
    loadChildren: () => import('./user-change-password/user-change-password.module').then( m => m.UserChangePasswordPageModule)
  },
  {
    path: 'cart-list',
    loadChildren: () => import('./cart-list/cart-list.module').then( m => m.CartListPageModule)
  },
  {
    path: 'cart-order-status',
    loadChildren: () => import('./cart-order-status/cart-order-status.module').then( m => m.CartOrderStatusPageModule)
  },
  {
    path: 'modal-image',
    loadChildren: () => import('./modal-image/modal-image.module').then( m => m.ModalImagePageModule)
  },
  {
    path: 'editar-compra/:id',
    loadChildren: () => import('./editar-compra/editar-compra.module').then( m => m.EditarCompraPageModule)
  },
  {
    path: 'modal-pago',
    loadChildren: () => import('./modal-pago/modal-pago.module').then( m => m.ModalPagoPageModule)
  },
  {
    path: 'detalle-cuenta',
    loadChildren: () => import('./detalle-cuenta/detalle-cuenta.module').then( m => m.DetalleCuentaPageModule)
  },
  {
    path: 'detalle-pago-cuenta',
    loadChildren: () => import('./detalle-pago-cuenta/detalle-pago-cuenta.module').then( m => m.DetallePagoCuentaPageModule)
  },
  {
    path: 'detalle-pedido',
    loadChildren: () => import('./detalle-pedido/detalle-pedido.module').then( m => m.DetallePedidoPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
